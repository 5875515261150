import React from 'react';
import Header from '../components/Header/Header'
import SEO from "../components/seo"

class Contact extends React.Component {
    render() {
        return (
            <div className='container'>
                <SEO title="Contact | Thousand Words" description="We use public data to tell interactive visual stories about the world." />
                <Header showSubtitle={true} />
                <div className="mw7 center">
                    <p>To contact us for inquiries or questions, please use the following email: info@thousandwords.gr</p>
                </div>
            </div>
        )
    }
}

export default Contact;
